import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=512ea401&scoped=true&lang=pug"
import script from "./Card.vue?vue&type=script&lang=js"
export * from "./Card.vue?vue&type=script&lang=js"
import style0 from "./Card.vue?vue&type=style&index=0&id=512ea401&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "512ea401",
  null
  
)

export default component.exports