<template lang="pug">
div.expande-horizontal.wrap(style="background: #f2f2f2; min-height: 100vh; justify-content: flex-start; align-items: flex-start")
  CheckoutDesktop(:plan_slug="plan_slug")

</template>

<script>
import CheckoutDesktop from "../components/DesktopPublic/Index.vue";
export default {
  props: ["plan_slug"],
  components: {
    CheckoutDesktop
  },
  data() {
    return {};
  }
};
</script>
