<template lang="pug">
v-flex(xs12)
  div.expande-horizontal.centraliza.wrap
    v-flex(xs12)
      div(style="background: #fff;border-radius: 6px;").cool-shaddow.expande-horizontal.wrap
        v-app-bar(style="border-top-right-radius: 6px;border-top-left-radius: 6px;" height="50" flat :color="$theme.third")
          v-icon(:size="18" color="#f2f2f2").mr-3 mdi-shield-check-outline
          h4(style="color: #f2f2f2;").fonte COMPRA 100% SEGURA
        div.expande-horizontal.pa-3
          v-list(three-line)
            v-list-item.fonte.pa-0.ma-0
              v-avatar(rounded size="120")
                v-img(contain src="/img/logo_mini.png")
              v-list-item-content 
                v-list-item-title
                  span.font-weight-bold {{ get_checkout.plan._id ? get_checkout.plan.name : 'Carregando...' }}
                v-list-item-subtitle(v-if="trial")
                  span.fonte.fonte-little R$ 0,00 
                v-list-item-subtitle.mb-2(v-if="trial")
                  span.grey--text {{ $helper.formataSaldo(get_checkout.plan._id ? (get_checkout.plan.value_cents) : 0) }} após {{ get_checkout.plan.trial }} dias
                v-list-item-subtitle(v-else)
                  h4 {{ $helper.formataSaldo(get_checkout.plan._id ? (get_checkout.plan.value_cents / 1000) : 0) }}
                v-list-item-subtitle(v-if="trial")
                  v-icon(color="green darken-2", size="16").mr-1 mdi-star
                  span.fonte.checkout-item-subtitle.green--text.font-mini(style="font-size: 9pt;") {{ get_checkout.plan.trial }} dias grátis, cancele quando quiser.
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      trial: true
    };
  },
  computed: {
    ...mapGetters(["get_checkout"])
  }
};
</script>
