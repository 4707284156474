<template lang="pug">
v-flex(xs12,:class="{ 'pr-3': $vuetify.breakpoint.lgAndUp }")
  div.expande-horizontal.centraliza.wrap
    v-flex(xs12).mb-3
      div.expande-horizontal.centraliza.wrap
        v-flex(xs12).mb-3
          v-list.pa-0.ma-0
            v-list-item.pa-0.ma-0
              v-avatar(:color="$theme.third").mr-3
                v-icon(color="#f2f2f2") mdi-account
              v-list-item-content
                v-list-item-title.fonte Informações do comprador
                v-list-item-subtitle.fonte.grey--text Preencha seus dados abaixo
            v-divider
      div.expande-horizontal.wrap.checkout-informations-container
        v-flex(xs12).fonte
          v-form(ref="form_checkout")
            div.expande-horizontal.wrap
              v-flex(xs12).mb-2
                span.fonteMini.fonte.font-weight-bold Seu nome completo
                v-text-field(
                  solo
                  flat
                  :rules="[v => !!v || 'preencha aqui']"
                  background-color="#f8f8f8"
                  placeholder="ex: Maria das Graças"
                  v-model="get_checkout.payer.name"
                  :color="$theme.primary"
                  hide-details
                )
              v-flex(xs12)
                span.fonteMini.fonte.font-weight-bold Seu endereço de email
                v-text-field(
                  solo
                  flat
                  background-color="#f8f8f8"
                  placeholder="ex: maria@gmail.com"
                  :rules="[v => !!v || 'preencha aqui']"
                  v-model="get_checkout.payer.email"
                  :color="$theme.primary"
                )
              v-flex(xs12 md6 :class="{ 'pr-3': $vuetify.breakpoint.lgAndUp }")
                span.fonteMini.fonte.font-weight-bold CPF
                v-text-field(
                  solo
                  flat
                  background-color="#f8f8f8"
                  placeholder="Insira seu CPF"
                  :rules="[v => (!!v && (validarCPF() || validarCNPJ())) || 'preencha aqui com dados válidos']"
                  v-mask=['###.###.###-##']
                  v-model="get_checkout.payer.cpf_cnpj"
                  :color="$theme.primary"
                )
              v-flex(xs12 md2 :class="{ 'pr-3': $vuetify.breakpoint.lgAndUp }")
                span.fonteMini.fonte.font-weight-bold DDD
                v-text-field(
                  solo
                  flat
                  background-color="#f8f8f8"
                  placeholder="ex: 96"
                  v-model="get_checkout.payer.phone_prefix"
                  :rules="[v => !!v || 'preencha aqui']"
                  :color="$theme.primary"
                )
              v-flex(xs12 md4)
                span.fonteMini.fonte.font-weight-bold Seu número de cel
                v-text-field(
                  solo
                  flat
                  background-color="#f8f8f8"
                  placeholder="ex: 98419 6827"
                  v-model="get_checkout.payer.phone"
                  :rules="[v => !!v || 'preencha aqui']"
                  :color="$theme.primary"
                  v-mask="['# #### ####']"
                )
              v-flex(xs12).px-2.pt-6
                v-btn.elevation-0.fonte.white--text.font-weight-bold(:loading="loading" :disabled="loading" @click="validateForm",:color="$theme.third" dark) Avançar
          

</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_checkout"])
  },
  methods: {
    ...mapActions(["checkout"]),
    validateForm() {
      if (this.$refs.form_checkout.validate()) {
        this.loading = true;
        this.get_checkout.seller = this.$route.query.s_id;
        this.checkout();
        setTimeout(() => {
          this.loading = false;
        }, 5000)
      }
    },
    validarCPF() {
      let cpf = this.get_checkout.payer.cpf_cnpj;
      cpf = cpf.replace(/[^\d]+/g, ""); // Remove caracteres não numéricos

      if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false; // CPF inválido se tiver menos ou mais de 11 dígitos ou se todos os dígitos forem iguais
      }

      var soma = 0;
      var resto;

      for (var i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }

      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) {
        resto = 0;
      }

      if (resto !== parseInt(cpf.substring(9, 10))) {
        return false; // Primeiro dígito verificador inválido
      }

      soma = 0;

      for (i = 1; i <= 10; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }

      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) {
        resto = 0;
      }

      if (resto !== parseInt(cpf.substring(10, 11))) {
        return false; // Segundo dígito verificador inválido
      }

      return true; // CPF válido
    },
    validarCNPJ() {
      let cnpj = this.get_checkout.payer.cpf_cnpj;
      cnpj = cnpj.replace(/[^\d]+/g, ""); // Remove caracteres não numéricos

      if (cnpj.length !== 14 || /^(\d)\1{13}$/.test(cnpj)) {
        return false; // CNPJ inválido se tiver menos ou mais de 14 dígitos ou se todos os dígitos forem iguais
      }

      var tamanho = cnpj.length - 2;
      var numeros = cnpj.substring(0, tamanho);
      var digitos = cnpj.substring(tamanho);
      var soma = 0;
      var pos = tamanho - 7;

      for (var i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }

      var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      if (resultado !== parseInt(digitos.charAt(0))) {
        return false; // Primeiro dígito verificador inválido
      }

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;

      for (i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }

      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      if (resultado !== parseInt(digitos.charAt(1))) {
        return false; // Segundo dígito verificador inválido
      }

      return true; // CNPJ válido
    }
  },
  data() {
    return {
      loading: false
    }
  }
};
</script>

<style>
.checkout-informations-font {
  font-weight: 500;
  font-size: 18px;
  line-height: 39px;
  color: #363636;
}
</style>
