<template lang="pug">
v-flex(xs12,:class="{'pl-3': $vuetify.breakpoint.lgAndUp}").fonte.pa-0.ma-0
  div.expande-horizontal.centraliza.wrap
    v-flex(xs12)
      div.expande-horizontal.pa-0.ma-0
        v-flex(xs12).mb-3.pa-0.ma-0
          v-list.pa-0.ma-0(style="min-width: 100%;")
            v-list-item.pa-0.ma-0
              v-avatar(color="#f2f2f2").mr-3
                v-icon(:color="$theme.secondary") mdi-cash-lock
              v-list-item-content
                v-list-item-title.fonte.font-weight-bold Método de pagamento
                v-list-item-subtitle.fonte.grey--text Escolha como deseja pagar
            v-divider
      div.expande-horizontal.wrap.checkout-paymentmethod-container.pt-6
        v-flex.pb-1(@click="selectMethod('card')",xs6 md3)
          div.clickable.checkout-card-method(:class="{ 'checkout-card-method-selected': get_checkout.method === 'card' }")
            img(src="img/checkout/checkout-card.svg")
            span.fonte Cartão de Crédito
        v-flex.pb-1(@click="selectMethod('bank_slip')",xs6 md3)
          div.clickable.checkout-card-method(:class="{ 'checkout-card-method-selected': get_checkout.method === 'bank_slip' }")
            img(src="img/checkout/checkout-boleto.svg")
            span.fonte Boleto
        //- v-flex(@click="selectMethod('pix')",xs7)
        //-   div.clickable.checkout-card-method(:class="{ 'checkout-card-method-selected': get_checkout.method === 'pix' }")
        //-     img(src="img/checkout/checkout-pix.svg")
        //-     span.fonte Pix

</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_checkout"])
  },
  methods: {
    selectMethod(method) {
      this.get_checkout.method = method;
      this.$vuetify.goTo(2000);
      this.$forceUpdate();
    }
  }
};
</script>

<style>
.checkout-paymentmethod-font {
  font-weight: 600;
  font-size: 18px;
  line-height: 39px;
  color: #363636;
}
.checkout-card-method {
  padding: 6px;
  width: 100%;
  margin-bottom: 24px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.checkout-card-method-selected {
  border: 2px solid #e1dffb;
}

.checkout-card-method img {
  height: 60px;
}

.checkout-card-method span {
  font-size: 12px;
}
.clickable {
  cursor: pointer;
}
</style>
