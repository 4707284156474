<template lang="pug">
v-dialog(v-model="modal",absolute,fullscreen,transition="slide-x-transition")
  div.expande-horizontal.centraliza.fonte.vitro(style="min-height: 100vh;").pa-2
    v-flex(xs12 md6)
      v-card.pa-6.pt-0
        //- Em caso de sucesso na compra 
        //- div.expande-horizontal.wrap(v-if="success")
        //-   v-flex(xs12).mb-6
        //-     div.expande-horizontal.centraliza
        //-       v-icon(color="green", size="70") mdi-check-circle-outline
        //-   v-flex(xs12).pb-4
        //-     div.expande-horizontal.centraliza
        //-       span.text-center(style="font-size: 27px;") Parabéns!
        //-   v-flex(xs12).pb-12(v-if="result.method === 'card' && get_checkout.trial")
        //-     div.expande-horizontal.centraliza
        //-       span.text-center Sua assinatura está prontinha, basta clicar no link que enviamos no seu email para configurar sua conta e aproveitar seus 7 dias totalmente grátis!
        //-   v-flex(xs12).pb-12(v-if="result.method === 'card' && !get_checkout.trial")
        //-     div.expande-horizontal.centraliza
        //-       span.text-center Sua assinatura está prontinha, basta clicar no link que enviamos no seu email para configurar sua conta!
        //-   v-flex(xs12).pb-12(v-if="result.method === 'bank_slip' && get_checkout.trial")
        //-     div.expande-horizontal.centraliza.column
        //-       span.text-center Sua assinatura está prontinha, basta clicar no link que enviamos no seu email para configurar sua conta e aproveitar seus 7 dias totalmente grátis!
        //-   v-flex(xs12).pb-12(v-if="result.method === 'bank_slip' && !get_checkout.trial")
        //-     div.expande-horizontal.centraliza.column
        //-       span.text-center Seu boleto foi gerado com sucesso, enviaremos também por email, assim que detectarmos o pagamento, o link para configurar sua conta será enviado no seu email!
        //-       v-btn.mt-6(:color="$theme.secondary" @click="goToBankSlip" dark v-if="result.external_data.pdf") Clique aqui para acessar seu boleto!
        //-   v-flex(xs12)
        //-     div.expande-horizontal.centraliza.column
        //-       span.text-center A Equipe SysRocket
        //-       span.text-center Agradece a sua preferência!
        //- div.expande-horizontal.wrap(v-if="error")
        div.expande-horizontal.wrap()
          v-flex(xs12).mb-2
            div.expande-horizontal.centraliza
              v-flex(xs6 md5)
                LottieAnimation(
                  class="pa-0"
                  ref="anim2"
                  :animationData="require('@/apps/shared/assets/insumos.json')"
                )
              //- v-icon(color="orange", size="70") mdi-close-circle-outline
          v-flex(xs12).pb-4
            div.expande-horizontal.centraliza
              span.text-center(style="font-size: 27px;") Opa!
          v-flex(xs12).pb-12
            div.expande-horizontal.centraliza.column
              span.text-center.grey--text(style="max-width: 300px;") {{ error_message }}
          v-flex(xs12)
            div.expande-horizontal.centraliza.column
              v-btn.elevation-0.fonte.font-weight-bold.fonteMini(rounded :color="$theme.primary" dark @click="tryAgain") Revisar e tentar novamente!

        //- span {{ result }}
        //- span.red--text Erro: {{ error }}

</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/main.js";
import LottieAnimation from "lottie-web-vue";
export default {
  data() {
    return {
      modal: false,
      error: false,
      error_message: "",
      result: {
        external_data: {
          success: false
        }
      }
    };
  },
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters(["get_checkout"]),
    success() {
      if (
        this.result &&
        this.result.external_data &&
        this.result.external_data.success
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    goToBankSlip() {
      window.open(this.result.external_data.pdf, "_blank");
    },
    tryAgain() {
      this.modal = false;
      this.error = false;
      this.error_message = "";
      this.result = {
        external_data: {
          success: false
        }
      };
    }
  },
  created() {
    EventBus.$on("modal-order-status", result => {
      this.result = result.charge;
      this.modal = true;
    });
    EventBus.$on("checkout-success", result => {
      this.result = result.charge;
      this.modal = true;
    });
    EventBus.$on("checkout-error", error => {
      this.modal = true;
      this.error = true;
      this.error_message = error;
    });
  }
};
</script>

<style scoped>
.checkout-methods-container {
  background: #f0f0f0;
  border: 1px solid #3cacba;
  border-radius: 20px;
  padding: 24px;
}
</style>
