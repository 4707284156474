<template lang="pug">
v-flex(xs12)
  div.expande-horizontal.wrap.pt-6
    v-flex(xs12).pr-12.pl-12
      div.expande-horizontal.card-method-card.column
        h3.fonte.card-title Boleto
    v-flex(xs6).pa-12.pt-6
        div.card-container.expande-horizontal.wrap.pa-6
          v-flex(xs12)
            div.expande-horizontal
              v-flex(xs12)
                div.expande-horizontal(v-if="brandCreditCard")
                  v-spacer
                  span.fonte.font-weight-bold.white--text {{ brandCreditCard }}
          v-flex(xs12)
            div.expande-horizontal
              v-icon(color="yellow",:size="40") mdi-integrated-circuit-chip
              v-spacer
          v-flex(xs12)
            div.expande-horizontal
              v-spacer
              span.fonte.white--text {{ card.full_name }}
          v-flex(xs12)
            div.expande-horizontal
              v-spacer
              span.fonte.white--text {{ card.number }}
          v-flex(xs12).mt-3
            div.expande-horizontal
              v-spacer
              span.fonte.font-weight-bold.white--text {{ card.expiration }}
              span.ml-3.fonte.font-weight-light.white--text {{ card.verification_value }}
          
    v-flex(xs6).pa-12
      div.card-method-form.expande-horizontal.wrap
        v-flex(xs12)
          v-form(ref="card_form")
            v-flex(xs12)
              v-text-field(
                data-iugu="number"
                dense
                label="Número do cartão",
                v-model="card.number"
                outlined,
                :rules="cardNumberRules"
                v-mask="['#### #### #### ####']"
                placeholder="#### #### #### ####"

              ) 
            v-flex(xs12)
              v-text-field(
                data-iugu="full_name"
                dense
                v-model="card.full_name"
                label="Nome no cartão",
                outlined
              ) 
            v-flex(xs12)
              div.expande-horizontal
                v-flex.mr-3(xs6)
                  v-text-field(
                    data-iugu="expiration"
                    v-model="card.expiration"
                    :rules="ExpirationRules",
                    dense,
                    label="Validade",
                    placeholder="MM/AAAA"
                    v-mask="['##/##', '##/####']"
                    outlined
                    hint="mes/ano"
                  ) 
                v-flex(xs6)
                  v-text-field(
                    data-iugu="verification_value"
                    dense
                    :rules="VerificationValueRules",
                    v-model="card.verification_value"
                    label="CVV",
                    v-mask="['####']"
                    outlined
                  ) 
        v-flex(xs12)
          v-btn.fonte(block,dark,@click="createCardToken",:color="$theme.secondary") Finalizar Compra        

</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      card: {
        number: "4111 1111 1111 1111",
        verification_value: "938",
        expiration: "02/28",
        full_name: "Patrick Lorran Ramos Carvalho"
      },
      // card: {
      //   number: "",
      //   verification_value: "",
      //   expiration: "",
      //   full_name: ""
      // },
      cardNumberRules: [
        v => !!v || "preencha o número do cartão",
        v => window.Iugu.utils.validateCreditCardNumber(v) || "número inválido"
      ],
      ExpirationRules: [
        v => !!v || "preencha a data",
        v => window.Iugu.utils.validateExpirationString(v) || "data inválida"
      ],
      VerificationValueRules: [
        v => !!v || "preencha o CVV",
        v =>
          window.Iugu.utils.validateCVV(
            v,
            window.Iugu.utils.getBrandByCreditCardNumber(this.cardNumber)
          ) || "CVV inválido"
      ]
    };
  },
  computed: {
    ...mapGetters(["get_checkout"]),
    brandCreditCard() {
      return window.Iugu.utils.getBrandByCreditCardNumber(this.cardNumber);
    },
    cardNumber() {
      if (this.card.number) {
        return this.card.number.replaceAll(" ", "");
      } else {
        return "";
      }
    }
  },
  methods: {
    ...mapActions(["checkout"]),
    createCardToken() {
      const self = this;
      const first_name = self.card.full_name.split(" ")[0];
      const last_name = self.card.full_name
        .split(" ")
        .filter(v => v !== first_name)
        .join(" ");
      const cc = window.Iugu.CreditCard(
        self.cardNumber,
        self.card.expiration.split("/")[0],
        self.card.expiration.split("/")[1],
        first_name,
        last_name,
        self.card.verification_value
      );
      if (this.$refs.card_form.validate() && cc.valid()) {
        const res = function(data) {
          if (data.errors) {
            self.$store.dispatch("createGlobalMessage", {
              timeout: 6000,
              type: "warning",
              message: "Verifique os dados do cartão, por favor!"
            });
            return;
          }
          self.get_checkout.token = data.id;
          self.checkout();
        };
        window.Iugu.createPaymentToken(cc, res);
      }
    }
  },
  created() {
    window.Iugu.setAccountID(process.env.VUE_APP_ID_ACCOUNT_IUGU);
    window.Iugu.setTestMode(true);
    // window.Iugu.setup();
  }
};
</script>

<style>
.card-method-card {
  max-width: 300px;
}
.card-title {
  color: #363636;
}
.card-container {
  width: 350px;
  display: flex;
  background: #f0f0f0;
  border: 1px solid #0da8f0;
  background: #0da8f0;
  border-radius: 10px;
}
</style>
