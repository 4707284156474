<template lang="pug">
v-flex(xs12)
  div.expande-horizontal.wrap.pt-0
    div.expande-horizontal.pa-0.ma-0
      v-flex(xs12).mb-3.pa-0.ma-0
        v-list(three-line).pa-0.ma-0(style="min-width: 100%;")
          v-list-item.pa-0.ma-0
            v-avatar(color="#f2f2f2").mr-3
              v-icon(:color="$theme.secondary") mdi-barcode
            v-list-item-content
              v-list-item-title.fonte.font-weight-bold Boleto selecionado
              v-list-item-subtitle.fonte.grey--text Para pagamentos via boleto, é necessário informar o endereço!
    v-flex(xs12).pt-6
      div.card-method-form.expande-horizontal.wrap.fonte
        v-flex(xs12)
          v-form(ref="payer_form")
            v-flex(xs7)
              v-text-field(
                dense
                label="CEP",
                v-model="get_checkout.payer.address.zip_code"
                outlined,
                type="number"
                @input="searchCep"
                :rules="cepRules"
                v-mask="['########']"
                placeholder="########"

              ) 
            div.expande-horizontal
              v-flex(xs9).pr-3
                v-text-field(
                  dense
                  v-model="get_checkout.payer.address.street"
                  label="Endereço",
                  outlined
                ) 
              v-flex(xs3)
                v-text-field(
                  dense
                  v-model="get_checkout.payer.address.number"
                  label="Nº",
                  ref="addressNumber"
                  outlined
                ) 
            v-flex(xs12)
              div.expande-horizontal
                v-flex.mr-3(xs6)
                  v-text-field(
                    v-model="get_checkout.payer.address.city"
                    dense,
                    label="Cidade",
                    placeholder="ex: Macapá"
                    outlined
                  ) 
                v-flex(xs6)
                  v-text-field(
                    v-model="get_checkout.payer.address.state"
                    dense,
                    label="Estado",
                    placeholder="ex: Amapá"
                    outlined
                  ) 
        v-flex(xs12)
          v-btn.fonte(block,dark,@click="createBankSlipChckout",:color="$theme.secondary") Finalizar Compra        

</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      cepRules: [v => !!v || "preencha seu cep"]
    };
  },
  computed: {
    ...mapGetters(["get_checkout"])
  },
  methods: {
    ...mapActions(["checkout", "get_cep"]),
    async searchCep() {
      setTimeout(async () => {
        if (
          this.get_checkout.payer.address.zip_code &&
          this.get_checkout.payer.address.zip_code.length === 8
        ) {
          const res = await this.get_cep(
            this.get_checkout.payer.address.zip_code
          );
          if (res.status === 200) {
            this.get_checkout.payer.address.street = res.data.logradouro;
            this.get_checkout.payer.address.district = res.data.bairro;
            this.get_checkout.payer.address.city = res.data.localidade;
            this.get_checkout.payer.address.state = res.data.uf;
            this.$refs.addressNumber.focus();
            this.$forceUpdate();
          }
        }
      }, 300);
    },
    createBankSlipChckout() {
      if (this.$refs.payer_form.validate()) {
        this.checkout();
      }
    }
  },
  created() {}
};
</script>

<style scoped>
.card-method-card {
  max-width: 300px;
}
.card-title {
  color: #363636;
}
.card-container {
  width: 100%;
  display: flex;
  background: #f0f0f0;
  border: 1px solid #0da8f0;
  border-radius: 10px;
}
</style>
